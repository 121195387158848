<template>
  <div>
	<!-- BREADCRUMB -->
	<div id="breadcrumb" class="section">
		<!-- container -->
		<div class="container">
			<!-- row -->
			<div class="row">
				<div class="col-md-12">
					<ul class="breadcrumb-tree">
            <li><router-link to="/">Home</router-link></li>
						<li class="active">Blog</li>
					</ul>
				</div>
			</div>
			<!-- /row -->
		</div>
		<!-- /container -->
	</div>
	<!-- /BREADCRUMB -->
	<!-- SECTION -->
	<div class="section">
		<div class="container">
			<div class="row">
				<div class="col-md-12 col-xs-12" style="text-align: center;padding-bottom: 30px;">
					<h3 class="title">Novidades FR Distribuidora</h3>
				</div>
				<div v-for="menu in blog" :key="menu.itiId" class="col-md-4 col-xs-6" style="text-align: center;padding-bottom: 60px;">
					<div class="product-img" v-if="menu.insInstitucionalImagens!=''">
						<router-link :to="'/blogDetalhe/'+ menu.itiId + '/' + menu.itiNome.replaceAll(' ', '-').replaceAll('/', '').replaceAll(',', '') ">
							<img :src="$imgURL + menu.insInstitucionalImagens[0].itgUrlImagem" alt="" style="width: 100%;border-radius: 5px;">
            </router-link>
					</div>
					<router-link :to="'/blogDetalhe/'+ menu.itiId + '/' + menu.itiNome.replaceAll(' ', '-').replaceAll('/', '').replaceAll(',', '') ">
						<p class="product-category" style="font-size: 18px;padding-top: 10px;">{{menu.itiNome}}</p>
					</router-link>
				</div>
			</div>
		</div>
	</div>
	<!-- /SECTION -->
  </div>
</template>

<script>
import moment from 'moment'
import axios from 'axios';
import { useRoute } from 'vue-router';
import { useHead } from '@vueuse/head';

export default {
  name: 'BlogView',
  components: {
  },
  data: () => ({
    blogtopo: [],
    servicocall: [],
    blog: [],
    newb: [],
  }),
  methods: {
    carregaMetas() {
      useHead({
        title: 'Blog - FR Distribuidora'
      })
    },
    formattedDate(value) {
      return this.$moment(value).format('DD/MM/YYYY')
    },
    carregaTexto(value) {
      this.newb = this.blog.filter(x => x.itiId == value)
      if (this.isMobile()) {
      this.scrollToTop();
    }
    },
    scrollToTop() {
    window.scrollTo({
      top: 0,
      behavior: "smooth" // Isso adicionará uma animação de rolagem suave
    });
  },
  isMobile() {
    return window.innerWidth <= 768; // Você pode ajustar esse valor de acordo com suas necessidades
  }
  },
  created() {
    this.carregaMetas();

    axios.get('/Process/InsInstitucional/institucional/28/blog')
      .then(response => {
        this.blog = response.data.filter(x=> x.itiVisivel).sort((a, b) => a.itiOrdem - b.itiOrdem);
        console.log('Blog')
        console.log(this.blog)
      

      })
      .catch(error => {
        console.error(error);
      });


  },
}
</script>

<style>
.das {
  display: block !important;
}

.slider-text h2 p {
  font-size: 60px;
  color: #081624;
  padding: 10px;
  display: inline-block;
  background: #99CC33;
  line-height: 1;
}</style>
