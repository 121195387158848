<template>
    <div>
        <!-- BREADCRUMB -->
        <div id="breadcrumb" class="section">
            <!-- container -->
            <div class="container">
                <!-- row -->
                <div class="row">
                    <div class="col-md-12">
                        <ul class="breadcrumb-tree">
                            <li><router-link to="/">Home</router-link></li>
                            <li><a href="#">Produtos</a></li>
                        </ul>
                    </div>
                </div>
                <!-- /row -->
            </div>
            <!-- /container -->
        </div>
        <!-- /BREADCRUMB -->

        <div class="page-content" style="padding-top: 30px;">
            <div class="container">
                <div class="row">
                    <aside class="col-lg-3">
                        <div class="sidebar sidebar-shop">
                            <div class="widget widget-collapsible">
                                <h3 class="widget-title">
                                    Categorias
                                </h3><!-- End .widget-title -->
                                <div class="collapse show" id="widget-1">
                                    <div class="widget-body">
                                        <button @click="VoltarNivel()" v-if="id != 0"
                                            class="btn btn-outline-primary-2 mb-3">Voltar</button>
                                        <div class="" v-for="seg in SegmentosMenu" :key="seg.psgId" style="line-height: 2;">
                                            <div class="">
                                                <a href="#" @click.prevent="MontaUrl(seg.psgNomeSegmento, seg.psgId)">

                                                    <i class="fa fa-angle-double-right" style="color:#e8522c;"></i>
                                                    <span
                                                        style="font-weight: 500; font-size: 16px;padding-left: 8px;color:#333333;">{{
                                                            seg.psgNomeSegmento }}</span>
                                                </a>
                                            </div><!-- End .custom-checkbox -->
                                        </div><!-- End .filter-item -->
                                    </div><!-- End .widget-body -->
                                </div><!-- End .collapse -->
                            </div><!-- End .widget -->
                        </div><!-- End .sidebar sidebar-shop -->
                    </aside><!-- End .col-lg-3 -->
                    <div class="col-lg-9">
                        <div class="toolbox">
                            <div class="toolbox-left col-md-9">
                                <div class="toolbox-info" style="color:#e8522c;font-size: 30px;font-weight: 500;">
                                    {{ NomeCategoria }}
                                </div><!-- End .toolbox-info -->
                            </div><!-- End .toolbox-left -->

                            <div class="toolbox-right col-md-2">
                                <div class="toolbox-sort">
                                    <v-select v-model="Ordenacao" :items="OrdenacaoAtivo" label="Ordenar por"
                                        @update:modelValue="OrdenaProdutos(Ordenacao)" item-title="nome" item-value="valor"
                                        variant="underlined" style="width: 180px;">
                                    </v-select>
                                </div><!-- End .toolbox-sort -->
                            </div><!-- End .toolbox-right -->
                        </div><!-- End .toolbox -->

                        <div class="products mb-3" style="padding-bottom: 120px;">
                            <div class="row justify-content-center" v-if="ProdutosFiltro != ''">
                                <div class="col-md-4 col-xs-6" v-for="prod4car in ProdutosFiltro" :key="prod4car.proProdutos.proId" style="height: 350px;">
                                    <div class="product">
                                        <router-link :to="'/produtodetalhe/' + prod4car.proProdutos.proId + '/' + prod4car.proProdutos.proNomeProduto.replaceAll(' ', '-').replaceAll('/', '').replaceAll(',', '')">
                                        <div v-if="prod4car.proProdutos.proCampoExtra10 != '' && prod4car.proProdutos.proCampoExtra10 != null" class="product-img" :style="{backgroundImage: 'url(' + $imgURL + prod4car.proProdutos.proCampoExtra10+ ')',backgroundRepeat: 'no-repeat', height: '230px',backgroundSize: 'cover', bacckgroundPosition: 'center'}">
                                            <div class="product-label" v-if="prod4car.proProdutos.proEtiqueta != '' && prod4car.proProdutos.proEtiqueta != null">
                                                <span class="sale" :style="{backgroundColor: prod4car.proProdutos.proEtiquetaCorFundo,color: prod4car.proProdutos.proEtiquetaCorFonte }">{{ prod4car.proProdutos.proEtiqueta }}</span>
                                            </div>
                                        </div>
                                    </router-link>
                                        <div class="product-body">
                                            <h3 class="product-name">
                                                <router-link :to="'/produtodetalhe/' + prod4car.proProdutos.proId + '/' + prod4car.proProdutos.proNomeProduto.replaceAll(' ', '-').replaceAll('/', '').replaceAll(',', '')">
                                                    {{ prod4car.proProdutos.proNomeProduto }}
                                                </router-link>
                                            </h3>
                                        </div>
                                        <div class="add-to-cart" v-if="prod4car.proProdutos.proVisivel">
                                            <router-link :to="'/produtodetalhe/' + prod4car.proProdutos.proId + '/' + prod4car.proProdutos.proNomeProduto.replaceAll(' ', '-').replaceAll('/', '').replaceAll(',', '')" class="add-to-cart-btn"
                                                style="padding-bottom:8px;padding-top:8px;"><i
                                                    class="fa fa-shopping-cart"></i>Orçamento</router-link>
                                        </div>
                                        <div class="product-price" v-else>
                                            <span class="new-price">Indisponível</span>
                                        </div>
                                    </div>
                                </div>
                            </div><!-- End .row -->
                        </div><!-- End .products -->
                    </div><!-- End .col-lg-9 -->

                </div><!-- End .row -->
            </div><!-- End .container -->
        </div><!-- End .page-content -->
    </div>
</template>

<script>
//import moment from 'moment'
import axios from 'axios';
import { useRoute } from 'vue-router';
import { useHead } from '@vueuse/head';

export default {
    name: 'ProdutosporCategoriaView',
    setup() {
        const route = useRoute();
        const id = route.params.idSegmentoProduto;
        const nomeSeg = route.params.nomeSegmento;
        const pesquisa = route.params.chavepesquisa;
        return {
            id, pesquisa
        };
    },
    components: {
    },
    data: () => ({
        informacoestopo: [],
        produtosSegmentos: [],
        Segmentos: [],
        SegmentosMenu: [],
        ProdutosGeral: [],
        ProdutosFiltro: [],
        infocall: [],
        CategoriaClicada: false,
        NomeCategoria: '',
        OrdenacaoAtivo: [{ nome: "A-Z", valor: 1 }, { nome: "Z-A", valor: 2 },],
        Ordenacao: '',
    }),
    methods: {
        carregaMetas() {
            useHead({
                title: 'Produtos - FR Distribuidora'
            })
        },
        formatarValor(valor) {
            return valor.toLocaleString('pt-BR', { minimumFractionDigits: 2 });
        },
        carregaTexto(value) {
            this.informacao = this.informacoes.filter(x => x.itiId == value)
        },
        OrdenaProdutos(value) {
            if (value == 1) {
                this.ProdutosFiltro = this.ProdutosFiltro.sort((a, b) => {
                    const nomeA = a.proProdutos.proNomeProduto || "";
                    const nomeB = b.proProdutos.proNomeProduto || "";
                    return nomeA.localeCompare(nomeB);
                });
            }
            else if (value == 2) {
                this.ProdutosFiltro = this.ProdutosFiltro.sort((a, b) => {
                    const nomeA = a.proProdutos.proNomeProduto || "";
                    const nomeB = b.proProdutos.proNomeProduto || "";
                    return nomeA.localeCompare(nomeB);
                }).reverse();
            }
            else if (value == 3) {
                this.ProdutosFiltro = this.ProdutosFiltro.sort((a, b) => {
                    const valorA = parseInt(a.proProdutos.proValorPor) || 0;
                    const valorB = parseInt(b.proProdutos.proValorPor) || 0;
                    return valorA - valorB;
                }).reverse();
            }
            else if (value == 4) {
                this.ProdutosFiltro = this.ProdutosFiltro.sort((a, b) => {
                    const valorA = parseInt(a.proProdutos.proValorPor) || 0;
                    const valorB = parseInt(b.proProdutos.proValorPor) || 0;
                    return valorA - valorB;
                });
            }

        },
        MontaUrl(nomeSegmento, psgId) {
            this.$router.push({
                name: 'Produtosporcategoria',
                params: {
                    idSegmentoProduto: psgId,
                    nomeSegmento: nomeSegmento.replaceAll(' ', '-').replaceAll('/', '').replaceAll(',', ''),
                },
                replace: true,
            });
        },
        filtraProdutos(nomeSegmento, psgId) {
            this.ProdutosFiltro = this.ProdutosGeral.filter(x => x.proProdutos.proVisivel && x.proSegmentos.psgId == psgId);

            this.SegmentosMenu = this.Segmentos.filter(x => x.psgIdPai == psgId && x.psgVisivel).sort((a, b) => {
                const nomeA = a.psgNomeSegmento || "";
                const nomeB = b.psgNomeSegmento || "";
                return nomeA.localeCompare(nomeB);
            });
            this.NomeCategoria = nomeSegmento;
        },
        VoltarNivel() {
            if (this.id != 0) {
                let _seg = this.Segmentos.filter(x => x.psgId == this.id);
                let _nome = 'Produtos em destaque';
                if (_seg[0].psgIdPai != 0) {
                    _nome = this.Segmentos.filter(x => x.psgId == _seg[0].psgIdPai)[0].psgNomeSegmento;
                }
                this.$router.push({
                    name: 'Produtosporcategoria',
                    params: {
                        idSegmentoProduto: _seg[0].psgIdPai,
                        nomeSegmento: _nome.replaceAll(' ', '-').replaceAll('/', '').replaceAll(',', ''),
                    },
                    replace: true,
                });
            }
        },
    },
    async created() {
        this.carregaMetas();

        await axios.get('/Process/ProProdutosSegmentos/GetAllBySite')
            .then(response => {
                //console.log(response.data)
                this.ProdutosGeral = response.data;
                console.log('this.ProdutosGeral')
                console.log(this.ProdutosGeral)
            })
            .catch(error => {
                console.error(error);
            });

        await axios.get('/Process/ProSegmentos/GetAll')
            .then(response => {
                this.Segmentos = response.data;
                this.SegmentosMenu = this.Segmentos.filter(x => x.psgIdPai == 0 && x.psgVisivel).sort((a, b) => {
                    const nomeA = a.psgNomeSegmento || "";
                    const nomeB = b.psgNomeSegmento || "";
                    return nomeA.localeCompare(nomeB);
                });

                this.NomeCategoria = 'Produtos em destaque';
            })
            .catch(error => {
                console.error(error);
            });


        if (this.pesquisa != null && this.pesquisa != '') {

            let _pesquisa = this.pesquisa.normalize('NFD').replace(/[\u0300-\u036f]/g, '').toLowerCase();

            let dados = this.ProdutosGeral.filter(x => x.proProdutos.proVisivel && x.proProdutos.proNomeProduto != null);
            let dados2 = dados.filter(x => x.proProdutos.proNomeProduto.normalize('NFD').replace(/[\u0300-\u036f]/g, '').toLowerCase().indexOf(_pesquisa) != -1
                || (x.proProdutos.proTexto1 != null && x.proProdutos.proTexto1.normalize('NFD').replace(/[\u0300-\u036f]/g, '').toLowerCase().indexOf(_pesquisa) != -1)
                || (x.proProdutos.proTexto2 != null && x.proProdutos.proTexto2.normalize('NFD').replace(/[\u0300-\u036f]/g, '').toLowerCase().indexOf(_pesquisa) != -1)
            );

            dados2.forEach(element => {
                if (this.ProdutosFiltro.filter(x => x.proProdutos.proId == element.proProdutos.proId).length == 0) {
                    this.ProdutosFiltro.push(element);
                }
            });
            this.NomeCategoria = this.pesquisa;
        }
        else {
            if (this.id == null || this.id == '' || this.id == 0) {
                let dados = this.ProdutosGeral.filter(x => x.proProdutos.proDestaque);
                dados.forEach(element => {
                    if (this.ProdutosFiltro.filter(x => x.proProdutos.proId == element.proProdutos.proId).length == 0) {
                        this.ProdutosFiltro.push(element);
                    }
                });
                console.log('this.ProdutosFiltro')
                console.log(this.ProdutosFiltro)
            }
            else {
                this.ProdutosFiltro = this.ProdutosGeral.filter(x => x.proProdutos.proVisivel && x.proSegmentos.psgId == this.id);

                this.SegmentosMenu = this.Segmentos.filter(x => x.psgIdPai == this.id && x.psgVisivel).sort((a, b) => {
                    const nomeA = a.psgNomeSegmento || "";
                    const nomeB = b.psgNomeSegmento || "";
                    return nomeA.localeCompare(nomeB);
                });
                this.NomeCategoria = this.Segmentos.filter(x => x.psgId == this.id)[0].psgNomeSegmento;
            }
        }
    },
}
</script>

<style>
.hhss {
    display: none;
}

.sli p {
    color: #ede3e3 !important;
}</style>
