<template>
    <div>
            <!-- BREADCRUMB -->
            <div id="breadcrumb" class="section">
                <!-- container -->
                <div class="container">
                    <!-- row -->
                    <div class="row">
                        <div class="col-md-12">
                            <ul class="breadcrumb-tree">
                                <li><router-link to="/">Home</router-link></li>
                                <li><a href="#">Produto</a></li>
                            </ul>
                        </div>
                    </div>
                    <!-- /row -->
                </div>
                <!-- /container -->
            </div>
            <!-- /BREADCRUMB -->
            <section>
                <div class="container" style="padding-bottom: 120px;">
                        <div class="row">
                            <div class="col-md-6">
                                <div class="product-gallery product-gallery-vertical">
                                    <div class="row" v-if="ProdutoImg!=''">
                                        <v-carousel show-arrows="hover" hide-delimiters>
                                            <v-carousel-item v-for="itemimg in ProdutoImg" :key="itemimg.pimId"
                                                :src="$imgURL + itemimg.pimUrlImagem"
                                                contain
                                            ></v-carousel-item>
                                        </v-carousel>
                                        
                                    </div><!-- End .row -->
                                </div><!-- End .product-gallery -->
                            </div><!-- End .col-md-6 -->

                            <div class="col-md-6" v-if="Produto!=''">
                                <div class="product-details product-details-centered">
                                    <h1 class="product-title" style="color: #e8522c;margin-bottom: 0px !important;font-weight: 500;">{{Produto.proNomeProduto}} </h1><div style="font-size: 14px;color:#333333;">Ref: {{Produto.proReferencia}}</div><!-- End .product-title -->
                                    <div class="row">
                                        <div class="col-lg-12 pb-3" style="padding: 20px;">
                                            <p v-html="Produto.proTexto1"></p>
                                        </div>
                                     </div>

                                    <div class="product-details-action bg-light" style="padding-top: 10px;">
                                        <div class="details-action-col">
                                            <div class="product-options">
                                                <label>
                                                    Qtde
                                                    <div class="input-number">
                                                        <input v-model="quantidade" type="number" style="width:80px;">
                                                    </div>
                                                </label>
                                            </div>
                                            <div class="add-to-cart">
                                            <a class="add-to-cart-btn" href="#" @click="AdicionaCarrinho()" style="padding-bottom:8px;padding-top:8px;">
                                              Adicionar Carrinho
                                            </a>
                                            </div>
                                         </div><!-- End .details-action-col -->
                                    </div><!-- End .product-details-action -->
                                </div><!-- End .product-details -->
                            </div><!-- End .col-md-6 -->
                        </div><!-- End .row -->
                </div><!-- End .container -->
            </section><!-- End .page-content -->
    </div>
</template>

<script>
//import moment from 'moment'
import axios from 'axios';
import { useRoute } from 'vue-router';
import { useHead } from '@vueuse/head';

export default {
    name: 'CestaDetalheView',
    setup() {
        const route = useRoute();
        const id = route.params.idProduto;
        const nomeProduto = route.params.nomeProduto;
        return {
            id
        };
    },
    components: {
    },
    data: () => ({
        Produto: [],
        ProdutoImg:[],
        infocall:[],
        valorTotal:0,
        vProdutosExtras:[],
        ProdutoAvulsos:[],
        DadosCompra: {
            produtos: [],
            mensagem: [],
            agenda: [],
            foto: [],
            avulsos: [],
            baloes: [],
            informacao: [],
            pagamento: [],
            cupom: [],
        },
        quantidade:1,
    }),
    methods: {
        carregaMetas() {
            useHead({
                title: 'Detalhe Produto - FR Distribuidora'
            })
        },
        formatarValor(valor) {
            return valor.toLocaleString('pt-BR', { minimumFractionDigits: 2 });
        },
        calculatotal(value){
            var valorAvulsos = 0;
            let dadosArmazenados = localStorage.getItem(this.$DadosCompra);
            if (dadosArmazenados) {
                dadosArmazenados = JSON.parse(dadosArmazenados);

                if (dadosArmazenados.avulsos != ''){
                    
                    dadosArmazenados.avulsos.forEach(item => {
                        valorAvulsos += item.praValorPor;
                        });
                }
            }
          this.valorTotal = value.proProdutosPrecos[0].ppcValorPor + valorAvulsos;
        },
       AdicionaCarrinho(){
            let dadosArmazenados = localStorage.getItem(this.$DadosCompra);
            if (dadosArmazenados) {
                dadosArmazenados = JSON.parse(dadosArmazenados);
                this.Produto.proQtde = this.quantidade;
                dadosArmazenados.produtos.push(this.Produto);
                localStorage.setItem(this.$DadosCompra, JSON.stringify(dadosArmazenados));
                this.$store.commit('updateVariavelHeader2', dadosArmazenados.produtos.length);
                this.$router.push('/carrinho')
            }
       },
    },
    created() {
        this.carregaMetas();

        let dadosArmazenados = localStorage.getItem(this.$DadosCompra);
        if (!dadosArmazenados) {
            dadosArmazenados = this.DadosCompra;
            localStorage.setItem(this.$DadosCompra, JSON.stringify(dadosArmazenados))
        }
        else {
            dadosArmazenados = JSON.parse(dadosArmazenados);

            if (dadosArmazenados.avulsos!=''){
                this.vProdutosExtras = dadosArmazenados.avulsos;
            }
        }

        if (this.id != null && this.id != '') {
            axios.get('/Process/ProProdutos/GetById/' + this.id)
                .then(response => {
                    this.Produto = response.data;
                      if (this.Produto.proProdutosImagens!=''){
                        this.ProdutoImg = this.Produto.proProdutosImagens.filter(x=> x.pimVisivel).sort((a, b) => {
                                                                            const valorA = parseInt(a.pimOrdem) || 0;
                                                                            const valorB = parseInt(b.pimOrdem) || 0;
                                                                            return valorA - valorB;
                                                                            });
                    }
                })
                .catch(error => {
                    console.error(error);
                });

                axios.get('/Process/ProAvulsosporProdutos/GetAllByProId/' + this.id)
                .then(response => {
                    this.ProdutoAvulsos = response.data.filter(x=> x.proProdutosAvulso!=null && x.proProdutosAvulso.proProdutosAvulsosImagens!=null);;
                    console.log('this.ProdutosAvulsos')
                    console.log(this.ProdutoAvulsos)
                })
                .catch(error => {
                    console.error(error);
                });
        }
    },
}
</script>

<style>.hhss {
    display: none;
}

.sli p {
    color: #ede3e3 !important;
}</style>
