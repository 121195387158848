<template>
  <div>
    <!-- FOOTER -->
    <footer id="footer" style="border-top:3px solid #E8522C;">
      <!-- top footer -->
      <div class="section">
        <!-- container -->
        <div class="container">
          <!-- row -->
          <div class="row" v-if="dados != ''">
            <div class="col-md-3 col-xs-6">
              <div class="footer">
                <h3 class="footer-title">Sobre</h3>
                <p v-html="dados[0].itiResumo1"></p>
                <ul class="footer-links">
                  <li><i class="fa fa-map-marker"></i>{{ dados[0].itiNome }}</li>
                  <li><a :href="dados[0].itiCampoExtra1" target="_blank"><i class="fa fa-phone"></i>{{ dados[0].itiReferencia }}</a></li>
                  <li><a href="#"><i class="fa fa-envelope-o"></i>{{ dados[0].itiCampoExtra2 }}</a></li>
                </ul>
              </div>
            </div>

            <div class="col-md-3 col-xs-6">
              <div class="footer">
                <h3 class="footer-title">Categorias</h3>
                <ul class="footer-links">
                  <li v-for="seg in SegmentosMenu" :key="seg.psgId">
                     <router-link :to="'/Produtosporcategoria/' + seg.psgId + '/' + seg.psgNomeSegmento.replaceAll(' ', '-').replaceAll('/', '').replaceAll(',', '') ">{{ seg.psgNomeSegmento }}</router-link></li>
              
                </ul>
              </div>
            </div>

            <div class="clearfix visible-xs"></div>

            <div class="col-md-3 col-xs-6">
              <div class="footer">
                <h3 class="footer-title">Institucional</h3>
                <ul class="footer-links">
                  <li><router-link to="/">Home</router-link></li>
                  <li><router-link to="/sobre">Sobre</router-link></li>
                  <li><router-link to="/contato">Contato</router-link></li>
                  <li><router-link to="/blog">Blog</router-link></li>
                  <li><router-link to="/carrinho">Orçamento</router-link></li>
                </ul>
              </div>
            </div>

            <div class="col-md-3 col-xs-6">
              <div class="footer">
                <h3 class="footer-title">Informações</h3>
                <ul class="footer-links">
                  <li v-for="itens in informacoes" :key="itens.itiId">
                    <router-link
                      :to="'/informacoes/' + itens.itiId + '/' + itens.itiNome.replaceAll(' ', '-').replaceAll('/', '').replaceAll(',', '')">
                      {{ itens.itiNome }}
                    </router-link>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <!-- /row -->
        </div>
        <!-- /container -->
      </div>
      <!-- /top footer -->
      <!-- bottom footer -->
      <div id="bottom-footer"
        style="padding-top: 10px;padding-bottom: 10px;background-color:#E8522C;border-top:1px solid #cccccc;">
        <div class="container">
          <!-- row -->
          <div class="row">
            <div class="col-md-12 text-center">
              <center><a href="https:www.avancedigital.com.br" style="color:#ffffff;font-size: 12px;"
                  target="_blank">Avance Digital</a></center>
            </div>
          </div>
          <!-- /row -->
        </div>
        <!-- /container -->
      </div>
      <!-- /bottom footer -->
    </footer>
    <!-- /FOOTER -->
    <div v-if="dados != ''" class="whatsapp-fixo">
      <a :href="dados[0].itiCampoExtra1" target="_blank">
        <img src="/images/atendimentowats.png" alt="Fale Conosco pelo WhatsApp" />
      </a>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
export default {
  name: 'PageFooter',
  components: {

  },
  data: () => ({
    dados: [],
    redes: [],
    informacoes: [],
    callaction: [],
    LinkWhats: [],
    SegmentosMenu: [],
  }),
  methods: {

  },
  async created() {
    axios.get('/Process/InsInstitucional/institucional/10/rodape')
      .then(response => {
        this.dados = response.data;
      })
      .catch(error => {
        console.error(error);
      });

    axios.get('/Process/InsInstitucional/institucional/20/redessociais')
      .then(response => {
        this.redes = response.data;
      })
      .catch(error => {
        console.error(error);
      });

    axios.get('/Process/InsInstitucional/institucional/18/informacoes')
      .then(response => {
        this.informacoes = response.data.filter(x => x.itiVisivel).sort((a, b) => a.itiOrdem - b.itiOrdem);
      })
      .catch(error => {
        console.error(error);
      });

      await axios.get('/Process/ProSegmentos/GetAll')
                    .then(response => {
                        this.Segmentos = response.data;
                        this.SegmentosMenu = this.Segmentos.filter(x => x.psgIdPai == 0 && x.psgVisivel).sort((a, b) => {
                            const nomeA = a.psgNomeSegmento || "";
                            const nomeB = b.psgNomeSegmento || "";
                            return nomeA.localeCompare(nomeB);
                        });

                        this.NomeCategoria = 'Cestas mais vendidas';
                    })
                    .catch(error => {
                        console.error(error);
                    });

  }
}
</script>

<style scoped>
.menuTamanho {
  font-size: 20px;
  text-align: center;
}

/* WHATSAPP FIXO */
.whatsapp-fixo {
  position: fixed;
  bottom: 10px;
  right: 10px;
  z-index: 999;
}
</style>
