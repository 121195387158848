<template>
  <v-app>
      <!--Loader v-if="$loading.value"></!--Loader-->
      <div class="page-wrapper">
        <Header />
          <router-view :key="$route.fullPath" />
        <FooterPage />
    </div><!-- End .page-wrapper -->
  </v-app>
</template>

<script>

import Header from './components/PageHeader.vue'
import FooterPage from './components/PageFooter.vue'
import Loader from './components/PageLoader.vue'

export default {
  name: 'App',
  components: {
    Header,
    FooterPage,
    Loader
  },
  data: () => ({
  }),
  methods: {
  },
  created() {
  },
}
</script>
<style>

@import "@/assets/css/bootstrap.min.css";
@import "@/assets/css/slick.css";
@import "@/assets/css/slick-theme.css";
@import "@/assets/css/nouislider.min.css";
@import "@/assets/css/font-awesome.min.css";
@import "@/assets/css/style.css";

@import "@/assets/css_cart/carrinho.css";
@import "@/assets/css_cart/calendario.css";
@import "@/assets/css_cart/login.css";
@import "@/assets/css_cart/minha_conta.css";
@import "@/assets/css_cart/nova_conta.css";
@import "@/assets/css_cart/pedido_realizado.css";
@import "@/assets/css_cart/status_conta.css";

@media (max-width:767px) {
  .hidden-xs {
    display: none !important;
  }
}

@media (min-width:768px) and (max-width:991px) {
  .hidden-sm {
    display: none !important;
  }
}

@media (min-width:992px) and (max-width:1199px) {
  .hidden-md {
    display: none !important;
  }
}

@media (min-width:1200px) {
  .hidden-lg {
    display: none !important;
  }
}

div:where(.swal2-container) h2:where(.swal2-title) {
    position: relative;
    max-width: 100%;
    margin: 0;
    padding: 0.8em 1em 0;
    color: inherit;
    font-size: 18px !important;
    font-weight: 600;
    text-align: center;
    text-transform: none;
    word-wrap: break-word;
}

.v-label {
    align-items: center;
    display: inline-flex;
    font-size: 15px !important;
    letter-spacing: .009375em;
    min-width: 0;
    opacity: var(--v-medium-emphasis-opacity);
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.input-group-text {
    font-size: 20px !important;
}

.vs__search,
.vs__search:focus {
  appearance: none;
  font-size: 45px !important;
  border: 1px solid transparent;
  border-left: none;
  outline: none;
  margin: 4px 0 0 0;
  padding: 0 7px;
  background: none;
  box-shadow: none;
  width: 0;
  max-width: 100%;
  flex-grow: 1;
  z-index: 1;
}

</style>
