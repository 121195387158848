<template>
    <div>
        <!-- BREADCRUMB -->
        <div id="breadcrumb" class="section">
            <!-- container -->
            <div class="container">
                <!-- row -->
                <div class="row">
                    <div class="col-md-12">
                        <ul class="breadcrumb-tree">
                            <li><router-link to="/">Home</router-link></li>
                            <li><a href="#">Carrinho</a></li>
                        </ul>
                    </div>
                </div>
                <!-- /row -->
            </div>
            <!-- /container -->
        </div>
        <!-- /BREADCRUMB -->
        <!-- SECTION -->
        <div class="section">
            <!-- container -->
            <div class="container">
                <!-- row -->
                <div class="row">

                    <div class="col-md-7">
                        <!-- Billing Details -->
                        <div class="billing-details">
                            <div class="section-title">
                                <h3 class="title">Dados Empresa e Entrega</h3>
                            </div>
                            <div class="row" style="padding-bottom: 10px;">
                                <div class="col-lg-4">
                                    <input class="input" v-model="nome" type="text" name="first-name" placeholder="Nome">
                                </div>
                                <div class="col-lg-5">
                                    <input class="input" v-model="email" type="text" name="last-name" placeholder="E-mail">
                                </div>
                                <div class="col-lg-3">
                                    <input class="input" v-model="telefone" type="text" name="fone"  placeholder="Telefone">
                                </div>
                            </div>
                            <div class="row" style="padding-bottom: 10px;">
                                <div class="col-lg-4">
                                    <input class="input" v-model="numCep" v-mask="'#####-###'" type="text" name="zip-code"
                                        placeholder="CEP Entrega">
                                </div>
                                <div class="col-lg-6">
                                    <a href="#" @click.prevent="Buscacep(numCep)"
                                        class="primary-btn order-submit">Verificar</a>
                                </div>
                            </div>
                            <div class="row" v-if="showend" style="padding-bottom: 10px;">
                                <div class="col-lg-12">
                                    <input class="input" v-model="endereco" type="text" name="address"
                                        placeholder="Endereço">
                                </div>
                            </div>
                            <div class="row" v-if="showend" style="padding-bottom: 10px;">
                                <div class="col-lg-4">
                                    <input class="input" v-model="numeroend" type="text" name="address"
                                        placeholder="Número">
                                </div>
                                <div class="col-lg-8">
                                    <input class="input" v-model="complemento" type="text" name="address"
                                        placeholder="Complemento">
                                </div>
                            </div>
                            <div class="row" v-if="showend" style="padding-bottom: 10px;">
                                <div class="col-lg-5">
                                    <input class="input" v-model="nomeCidade" type="text" name="city" placeholder="Cidade">
                                </div>
                                <div class="col-lg-3" v-if="showend">
                                    <input class="input" v-model="siglaEstado" v-mask="'AA'" type="text" name="city"
                                        placeholder="UF">
                                </div>
                                <div class="col-lg-4" v-if="showend">
                                    <input class="input" v-model="nomeBairro" type="text" name="country"
                                        placeholder="Bairro">
                                </div>
                            </div>
                        </div>
                        <!-- /Billing Details -->

                        <!-- Shiping Details -->
                        <div class="shiping-details" v-show="false">
                            <div class="section-title">
                                <h3 class="title">Observações sobre Orçamento</h3>
                            </div>
                            <div class="order-notes">
                                <textarea class="input" v-model="Observacoes" placeholder="Observações"></textarea>
                            </div>
                        </div>
                        <!-- /Shiping Details -->
                    </div>

                    <!-- Order Details -->
                    <div class="col-md-5 order-details">
                        <div class="section-title text-center">
                            <h3 class="title">Itens do Orçamento</h3>
                        </div>
                        <div class="order-summary">
                            <div class="order-col">
                                <div style="width: 20%;"><strong>Img</strong></div>
                                <div style="width: 50%;"><strong>Produto</strong></div>
                                <div style="width: 20%;"><strong>Quantidade</strong></div>
                                <div style="width: 10%;"><strong>Excluir</strong></div>
                            </div>
                            <div class="order-products" v-for="(itemp, index) in DadosCompra.produtos" :key="index">
                                <div class="order-col">
                                    <div style="width: 20%;">
                                        <img class="img-cart img-fluid"
                                            style="border:1px solid #cccccc;height: 80px;width: auto;"
                                            :src="$imgURL + itemp.proProdutosImagens[0].pimUrlImagem">
                                    </div>
                                    <div style="width: 50%;">{{ itemp.proReferencia }} - {{ itemp.proNomeProduto }}</div>
                                    <div style="width: 20%;text-align: center;">{{ itemp.proQtde }}</div>
                                    <div style="width: 10%;text-align: center;">
                                        <a data-toggle="tooltip" data-placement="top" @click="ExcluiProduto(index)"
                                            title="Excluir">
                                            <i class="fa fa-window-close"></i>
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <a href="#" @click.prevent="carregaDados()" class="primary-btn order-submit">Enviar Orçamento</a>
                    </div>
                    <!-- /Order Details -->
                </div>
                <!-- /row -->
            </div>
            <!-- /container -->
        </div>
        <!-- /SECTION -->
    </div>
</template>

<script>
import moment from 'moment'
import axios from 'axios';
import { useHead } from '@vueuse/head';
import Cookies from 'js-cookie';

export default {
    name: 'CarrinhoView',
    components: {
    },
    data: () => ({
        textoCarrinho: [],
        Produto: [],
        ProdutoImg: [],
        DadosCompra: {
            produtos: [],
            mensagem: [],
            agenda: [],
            foto: [],
            avulsos: [],
            baloes: [],
            informacao: [],
            pagamento: [],
            cupom: [],
        },
        nome: '',
        cnpj: '',
        responsavel: '',
        telefone: '',
        email: '',
        endereco: '',
        numeroend: '',
        complemento: '',
        siglaEstado: '',
        nomeCidade: '',
        nomeBairro: '',
        numCep: '',
        Observacoes: '',
        Retornocep: {},
        showend: false,
        contato:[],
    }),
    methods: {
        carregaMetas() {
            useHead({
                title: 'Carrinho - FR Distribuidora'
            })
        },
        formatarValor(valor) {
            return valor.toLocaleString('pt-BR', { minimumFractionDigits: 2 });
        },
        Buscacep(value) {
    fetch("https://viacep.com.br/ws/" + value + "/json/")
        .then(response => {
            if (!response.ok) {
                throw new Error("CEP inexistente");
            }
            return response.json();
        })
        .then(data => {
            this.Retornocep = data;
            if (this.Retornocep != null) {
                this.endereco = this.Retornocep.logradouro;
                this.numeroend = null;
                this.complemento = null;
                this.siglaEstado = this.Retornocep.uf;
                this.nomeCidade = this.Retornocep.localidade;
                this.nomeBairro = this.Retornocep.bairro;
                this.showend = true;
            } else {
                alert("CEP inexistente");
            }
        })
        .catch(error => {
            console.error(error);
        });
},
        ExcluiProduto(index) {
            let dadosArmazenados = localStorage.getItem(this.$DadosCompra);

            if (dadosArmazenados) {
                dadosArmazenados = JSON.parse(dadosArmazenados);
                dadosArmazenados.produtos.splice(index, 1);

                if (dadosArmazenados.produtos.length == 0) {
                    localStorage.setItem(this.$DadosCompra, JSON.stringify(dadosArmazenados));
                    this.$router.go(0);
                    this.$mensagemAviso("Carrinho vazio, escolha produtos e adicione no carrinho.")
                }
                else {
                    localStorage.setItem(this.$DadosCompra, JSON.stringify(dadosArmazenados));
                    this.$store.commit('updateVariavelHeader2', dadosArmazenados.produtos.length);
                    this.DadosCompra.produtos = [...dadosArmazenados.produtos];
                }
            }
        },
        carregaDados() {
            if (this.numCep == '') {
                this.$mensagemAviso("Preencha o CEP de entrega e clique em verificar.");
            } else if (this.nome == '') {
                this.$mensagemAviso("Preencha o campo nome");
            } else if (this.telefone == '') {
                this.$mensagemAviso("Preencha o telefone");
            } else if (this.email == '') {
                this.$mensagemAviso("Preencha o e-mail");
           /* } else if (this.cnpj == '') {
                this.$mensagemAviso("Preencha o campo CPF ou CNPJ");*/
          /*  } else if (this.responsavel == '') {
                this.$mensagemAviso("Preencha o campo nome responsável");*/
            } else if (this.endereco == '') {
                this.$mensagemAviso("Preencha o endereço de entrega");
            } else if (this.numeroend == null) {
                this.$mensagemAviso("Preencha o número do endereço de entrega");
            } else if (this.complemento == '') {
                this.$mensagemAviso("Preencha o complemento do endereço de entrega");
            } else if (this.nomeCidade == '') {
                this.$mensagemAviso("Preencha a cidade do endereço de entrega");
            } else if (this.siglaEstado == '') {
                this.$mensagemAviso("Preencha a sigla do estado do endereço de entrega");
            } else if (this.nomeBairro == '') {
                this.$mensagemAviso("Preencha o bairro do endereço de entrega");
            }
            else {
                let dadosArmazenados = localStorage.getItem(this.$DadosCompra);
                if (dadosArmazenados) {
                    dadosArmazenados = JSON.parse(dadosArmazenados);
                    if (dadosArmazenados.agenda[0] != null) {
                        dadosArmazenados.agenda[0] = {
                            nome: this.nome,
                            telefone: this.telefone,
                            email: this.email,
                            cnpj: this.cnpj,
                            responsavel: this.responsavel,
                            estado: this.siglaEstado,
                            cidade: this.nomeCidade,
                            bairro: this.nomeBairro,
                            endereco: this.endereco,
                            numero: this.numeroend,
                            complemento: this.complemento,
                            cep: this.numCep,
                            Obs: this.Observacoes
                        };
                    }
                    else {
                        dadosArmazenados.agenda.push({
                            nome: this.nome,
                            telefone: this.telefone,
                            email: this.email,
                            cnpj: this.cnpj,
                            responsavel: this.responsavel,
                            estado: this.siglaEstado,
                            cidade: this.nomeCidade,
                            bairro: this.nomeBairro,
                            endereco: this.endereco,
                            numero: this.numeroend,
                            complemento: this.complemento,
                            cep: this.numCep,
                            Obs: this.Observacoes
                        });
                    }
                }
                localStorage.setItem(this.$DadosCompra, JSON.stringify(dadosArmazenados))
                this.EnviaEmail();
                
            }
        },
        EnviaEmail() {
            //console.log('aqui')
            let dadosArmazenados = localStorage.getItem(this.$DadosCompra);
                dadosArmazenados = JSON.parse(dadosArmazenados);

            var dadosPedido = "<strong>Dados Cadastro</strong> <br><br>";
                dadosPedido += "Nome: " + dadosArmazenados.agenda[0].nome + "<br>";
                dadosPedido += "Telefone: " + dadosArmazenados.agenda[0].telefone + "<br>";
                dadosPedido += "E-mail: " + dadosArmazenados.agenda[0].email + "<br>";
               // dadosPedido += "CNPJ/CPF: " + dadosArmazenados.agenda[0].cnpj + "<br>";
               // dadosPedido += "Responsável: " + dadosArmazenados.agenda[0].responsavel + "<br>";
                dadosPedido += "Endereço: " + dadosArmazenados.agenda[0].endereco + "<br>";
                dadosPedido += "Numero: " + dadosArmazenados.agenda[0].numero + "<br>";
                dadosPedido += "Complemento: " + dadosArmazenados.agenda[0].complemento + "<br>";
                dadosPedido += "CEP: " + dadosArmazenados.agenda[0].cep + "<br>";
                dadosPedido += "Cidade: " + dadosArmazenados.agenda[0].cidade + "<br>";
                dadosPedido += "Bairro: " + dadosArmazenados.agenda[0].bairro + "<br>";
                dadosPedido += "Estado: " + dadosArmazenados.agenda[0].estado ;
                
                dadosPedido += " <br><br> <strong>Dados Produtos</strong> <br><br>" ;
                dadosArmazenados.produtos.forEach((itemex) => {
                    dadosPedido += "Produto Id: " + itemex.proReferencia + "<br>"
                    dadosPedido += "Nome: " + itemex.proNomeProduto + "<br>"
                    dadosPedido += "Quantidade: " + itemex.proQtde + "<br><br><hr>"
                });
    
            let _dadosEmail = {
                nomeDe:  dadosArmazenados.agenda[0].nome,
                nomePara: "FR Distribuidora",
                assunto: 'Solicitação de Orçamento Realizado no Site FR Distribuidora - Nome:  - ' + dadosArmazenados.agenda[0].nome,
                destinatario: this.contato[0].itiCampoExtra1,
                emailResposta: dadosArmazenados.agenda[0].email,
                textoEmail: dadosPedido,
                nomeAnexo: '',
                anexo: '',
                tipoAnexo: ''
            }

            axios.post(`/Process/AdmControleSmtp/Enviamail`, _dadosEmail)
                .then(response => {
                    if (response.data >= 1) {
                        this.$router.push("/pedidoRealizado");
                    }
                    else{
                        this.$mensagemSucesso("Houve um problema com envio do email, tente novamente mais tarde.")
                    }
                });
        },
    },
    created() {
        this.carregaMetas();

        axios.get('/Process/InsInstitucional/institucional/16/contato')
      .then(response => {
        this.contato = response.data;
      })
      .catch(error => {
        console.error(error);
      });

        let dadosArmazenados = localStorage.getItem(this.$DadosCompra);
        if (!dadosArmazenados) {
            dadosArmazenados = this.DadosCompra;
            localStorage.setItem(this.$DadosCompra, JSON.stringify(dadosArmazenados))
            this.$mensagemAviso("Carrinho vazio, escolha produtos e adicione no carrinho.")
            this.$router.push('/')
        }
        else {
            dadosArmazenados = JSON.parse(dadosArmazenados);
            this.DadosCompra = dadosArmazenados;
            if (this.DadosCompra.produtos.length == 0) {
                this.$mensagemAviso("Carrinho vazio, escolha produtos e adicione no carrinho.")
                this.$router.push('/')
            }
            if (this.DadosCompra.agenda.length > 0) {
                this.nome = this.DadosCompra.agenda[0].nome;
                this.telefone = this.DadosCompra.agenda[0].telefone;
                this.email = this.DadosCompra.agenda[0].email;
                this.cnpj = this.DadosCompra.agenda[0].cnpj;
                this.responsavel = this.DadosCompra.agenda[0].responsavel;
                this.siglaEstado = this.DadosCompra.agenda[0].estado;
                this.nomeCidade = this.DadosCompra.agenda[0].cidade;
                this.nomeBairro = this.DadosCompra.agenda[0].bairro;
                this.endereco = this.DadosCompra.agenda[0].endereco;
                this.numeroend = this.DadosCompra.agenda[0].numero;
                this.complemento = this.DadosCompra.agenda[0].complemento;
                this.numCep = this.DadosCompra.agenda[0].cep;
                this.Observacoes = this.DadosCompra.agenda[0].Obs;
                this.showend = true;
            }

        }
    },
}
</script>

<style>
.hhss {
    display: none;
}

.sli p {
    color: #ede3e3 !important;
}</style>
