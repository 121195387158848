<template>
  <div>
    <!-- BREADCRUMB -->
    <div id="breadcrumb" class="section">
        <!-- container -->
        <div class="container">
            <!-- row -->
            <div class="row">
                <div class="col-md-12">
                    <ul class="breadcrumb-tree">
                      <li><router-link to="/">Home</router-link></li>
                        <li class="active">Informações</li>
                    </ul>
                </div>
            </div>
            <!-- /row -->
        </div>
        <!-- /container -->
    </div>
    <!-- /BREADCRUMB -->
    <div class="page-content">
      <div class="container">
        <div class="row">
          <aside class="col-lg-3">
            <div class="sidebar">
              <div class="widget widget-cats">
                <h3 class="widget-title" style="text-decoration:underline;">Menu Informações</h3>
                <!-- End .widget-title -->
                <ul v-if="informacoes!=''">
                  <li style="padding-top: 5px;padding-bottom: 5px;" v-for="itens in informacoes" :key="itens.itiId">
                    <a href="#" @click.prevent="carregaTexto(itens.itiId)"> {{itens.itiNome}}</a>
                  </li>
                </ul>
              </div><!-- End .widget -->
            </div><!-- End .sidebar sidebar-shop -->
          </aside><!-- End .col-lg-3 -->
          <div class="col-lg-9" v-if="informacao!=''" style="padding-bottom: 60px;">
            <article class="entry single-entry">
              <div class="entry-body">
                <h2 class="entry-title pb-3">
                  {{informacao[0].itiNome}}
                </h2><!-- End .entry-title -->

                <div class="entry-content editor-content">
                  <p v-html="informacao[0].itiResumo1"></p>
                </div><!-- End .entry-content -->

              </div><!-- End .entry-body -->
            </article><!-- End .entry -->
          </div><!-- End .col-lg-9 -->
        </div><!-- End .row -->
      </div><!-- End .container -->
    </div><!-- End .page-content -->
  </div>
</template>

<script>
//import moment from 'moment'
import axios from 'axios';
import { useRoute } from 'vue-router';
import { useHead } from '@vueuse/head';

export default {
  name: 'InformacoesView',
  setup() {
    const route = useRoute();
    const id = route.params.idInformacoes;
    return {
      id
    };
  },
  components: {
  },
  data: () => ({
    informacoestopo: [],
    informacoes: [],
    informacao:[],
    infocall: [],
  }),
  methods: {
    carregaMetas() {
      useHead({
        title: 'Informações - FR Distribuidora'
      })
    },
    carregaTexto(value) {
      this.informacao = this.informacoes.filter(x => x.itiId == value)
    },
  },
  created() {

    this.carregaMetas();

    axios.get('/Process/InsInstitucional/institucional/18/informacoes')
      .then(response => {
        this.informacoes = response.data.filter(x => x.itiVisivel).sort((a, b) => a.itiOrdem - b.itiOrdem);

        if (this.id) {
          this.carregaTexto(this.id);
        }
        else {
          this.carregaTexto(this.informacoes[0].itiId);
        }
      })
      .catch(error => {
        console.error(error);
      });

  },
}
</script>

<style>.hhss {
  display: none;
}

.sli p {
  color: #ede3e3 !important;
}</style>
