<template>
  <div>
    <!-- BREADCRUMB -->
    <div id="breadcrumb" class="section">
      <!-- container -->
      <div class="container">
        <!-- row -->
        <div class="row">
          <div class="col-md-12">
            <ul class="breadcrumb-tree">
              <li><router-link to="/">Home</router-link></li>
              <li class="active">Contato</li>
            </ul>
          </div>
        </div>
        <!-- /row -->
      </div>
      <!-- /container -->
    </div>
    <!-- /BREADCRUMB -->

    <div class="section" v-if="contato != ''">
      <!-- container -->
      <div class="container">
        <!-- row -->
        <div class="row">

          <div class="col-md-7">
            <!-- Billing Details -->
            <div class="billing-details">
              <div class="section-title">
                <h3 class="title" style="padding-bottom: 20px;">{{ contato[0].itiNome }}</h3>
                <p v-html="contato[0].itiReferencia"></p>
                <p v-html="contato[0].itiResumo1"></p>
                <p v-html="contato[0].itiResumo2"></p>
              </div>
              <div class="form-group">
                <input class="input" v-model="Nome" type="text" name="Nome" placeholder="Nome">
              </div>
              <div class="form-group">
                <input class="input" v-model="Email" type="email" name="e-mail" placeholder="E-mail">
              </div>
              <div class="form-group">
                <input class="input" v-model="Telefone" type="text" name="telefone" placeholder="Telefone">
              </div>
              <div class="form-group">
                <input class="input" v-model="Assunto" type="text" name="telefone" placeholder="Telefone">
              </div>
              <div class="form-group">
                <textarea rows="8" v-model="Mensagem" class="input" placeholder="Mensagem"
                  style="width: 100%;"></textarea>
              </div>
              <a href="#" @click.prevent="EnviaEmail()" class="primary-btn order-submit">Enviar</a>
            </div>
          </div>

          <!-- Order Details -->
          <div class="col-md-5 order-details">
            <div class="section-title text-center">
              <h3 class="title">Onde Estamos</h3>
            </div>
            <iframe
              :src="'https://www.google.com/maps/embed/v1/place?key=AIzaSyDmXp4J53f9Wt7iHX7dzIn7rHG7yL-EXXo&q=' + contato[0].itiCampoExtra2"
              width="100%" height="450px" frameborder="0" style="border:0;" allowfullscreen="" aria-hidden="false"
              tabindex="0">
            </iframe>
          </div>
        </div>
        <!-- /Order Details -->
      </div>
      <!-- /row -->
    </div>
    <!-- /container -->

  </div>
</template>

<script>
//import moment from 'moment'
import axios from 'axios';
import { useHead } from '@vueuse/head';

export default {
  name: 'ContatoView',
  components: {
  },
  data: () => ({
    contatotopo: [],
    contato: [],
    Nome: '',
    Email: '',
    Telefone: '',
    Assunto: '',
    Mensagem: '',
    redes: [],
  }),
  methods: {
    carregaMetas() {
      useHead({
        title: 'Fale Conosco - FR Distribuidora'
      })
    },
    EnviaEmail() {
      if (this.Nome == '') {
        this.$mensagemAviso("Nome Obrigatório.")
      }
      else if (this.Email == '') {
        this.$mensagemAviso("E-mail Obrigatório.")
      }
      else {
        let _dadosEmail = {
          nomeDe: this.Nome,
          nomePara: "FR Distribuidora",
          assunto: 'Contato do Site FR Distribuidora - ' + this.Assunto,
          destinatario: this.contato[0].itiCampoExtra1,
          emailResposta: this.Email,
          textoEmail: "Nome: " + this.Nome + "<br>" + "E-mail: " + this.Email + "<br>Telefone: " + this.Telefone + "<br>Mensagem: " + this.Mensagem,
          nomeAnexo: '',
          anexo: '',
          tipoAnexo: ''
        }
        axios.post(`/Process/AdmControleSmtp/Enviamail`, _dadosEmail)
          .then(response => {
            if (response.data >= 1) {
              this.$mensagemSucesso("E-mail enviado com sucesso.")
              this.Nome = "";
              this.Email = "";
              this.Telefone = "";
              this.Assunto = "";
              this.Mensagem = "";
            }
          });
      }
    },
  },
  created() {
    this.carregaMetas();

    axios.get('/Process/InsInstitucional/institucional/16/contato')
      .then(response => {
        this.contato = response.data;
      })
      .catch(error => {
        console.error(error);
      });

  },
}
</script>

<style>
.das {
  display: block !important;
}

.slider-text h2 p {
  font-size: 60px;
  color: #081624;
  padding: 10px;
  display: inline-block;
  background: #99CC33;
  line-height: 1;
}</style>
