<template>
  <div>
	<!-- BREADCRUMB -->
	<div id="breadcrumb" class="section">
		<!-- container -->
		<div class="container">
			<!-- row -->
			<div class="row">
				<div class="col-md-12">
					<ul class="breadcrumb-tree">
						<li><router-link to="/">Home</router-link></li>
						<li class="active">Blog</li>
					</ul>
				</div>
			</div>
			<!-- /row -->
		</div>
		<!-- /container -->
	</div>
	<!-- /BREADCRUMB -->
  <div class="section" v-if="blog!=''">
        <div class="container">
            <div class="row">
                <div class="col-md-12 col-xs-12" style="text-align: center;padding-bottom: 30px;">
                    <span style="float: right;"> <a type="" href="javascript:window.history.go(-1)">Voltar
                        </a></span>
                    <h3 class="title">{{blog[0].itiNome}}</h3>
                </div>
                <div class="col-md-6 col-xs-6" style="text-align: center;padding-bottom: 60px;">
                    <div class="product-img"  v-if="blog[0].insInstitucionalImagens!=''">
                        <img :src="$imgURL + blog[0].insInstitucionalImagens[0].itgUrlImagem" alt="" style="width: 100%;">
                    </div>
                </div>
                <div class="col-md-6 col-xs-6" style="text-align: center;padding-bottom: 60px;">
                    <p v-html="blog[0].itiResumo1" class="product-category" style="font-size: 13px;padding-top: 10px; text-align: justify;">
                    </p>
                </div>
            </div>
        </div>
    </div>
  </div>
</template>

<script>
import moment from 'moment'
import axios from 'axios';
import { useRoute } from 'vue-router';
import { useHead } from '@vueuse/head';

export default {
  name: 'BlogView',
  setup() {
    const route = useRoute();
    const id = route.params.idBlog;
    return {
      id
    };
  },
  components: {
  },
  data: () => ({
    blogtopo: [],
    servicocall: [],
    blog: [],
    newb: [],
  }),
  methods: {
    carregaMetas() {
      useHead({
        title: 'Blog Detalhe - FR Distribuidora'
      })
    },
    formattedDate(value) {
      return this.$moment(value).format('DD/MM/YYYY')
    },
    carregaTexto(value) {
      this.newb = this.blog.filter(x => x.itiId == value)
      if (this.isMobile()) {
      this.scrollToTop();
    }
    },
    scrollToTop() {
    window.scrollTo({
      top: 0,
      behavior: "smooth" // Isso adicionará uma animação de rolagem suave
    });
  },
  isMobile() {
    return window.innerWidth <= 768; // Você pode ajustar esse valor de acordo com suas necessidades
  }
  },
  created() {
    this.carregaMetas();

    axios.get('/Process/InsInstitucional/institucional/28/blog')
      .then(response => {
        this.blog = response.data.filter(x=> x.itiVisivel && x.itiId == this.id);
        console.log('Blog')
        console.log(this.blog)
      

      })
      .catch(error => {
        console.error(error);
      });


  },
}
</script>

<style>
.das {
  display: block !important;
}

.slider-text h2 p {
  font-size: 60px;
  color: #081624;
  padding: 10px;
  display: inline-block;
  background: #99CC33;
  line-height: 1;
}</style>
