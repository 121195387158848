<template>
    <div>
        <section class="" v-if="contato!=''"
            :style="{backgroundImage: 'url(' + $imgURL + contato[0].insInstitucionalImagens[0].itgUrlImagem + ')',backgroundRepeat: 'no-repeat', height: '500px',backgroundsSize: 'cover'}">
            <div>
                <div class="container">
                    <div class="row">
                        <div class="col-md-8 col-lg-5" style="padding-top: 160px;">
                            <div class="content p-4">
                                <h2 style="color:#ffffff;">{{contato[0].itiNome}}</h2>
                                <p style="color:#ffffff;font-size:20px;" v-html="contato[0].itiResumo1"></p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </div>
</template>

<script>
import moment from 'moment'
import axios from 'axios';
import { useHead } from '@vueuse/head';
import Cookies from 'js-cookie';

export default {
    name: 'PedidoRealizadoView',
    components: {
    },
    data: () => ({
        textoCarrinho: [],
        Produto: [],
        ProdutoImg: [],
        DadosCompra: {
            produtos: [],
            mensagem: [],
            agenda: [],
            foto: [],
            avulsos: [],
            baloes: [],
            informacao: [],
            pagamento: [],
            cupom: [],
        },
        nome: '',
        cnpj: '',
        responsavel: '',
        telefone: '',
        email: '',
        endereco: '',
        numeroend: '',
        complemento: '',
        siglaEstado: '',
        nomeCidade: '',
        nomeBairro: '',
        numCep: '',
        Observacoes: '',
        Retornocep: {},
        showend: false,
        contato:[],
    }),
    methods: {
        carregaMetas() {
            useHead({
                title: 'Pedido Realizado - FR Distribuidora'
            })
        },
        formatarValor(valor) {
            return valor.toLocaleString('pt-BR', { minimumFractionDigits: 2 });
        },
    },
    created() {
        this.carregaMetas();

        axios.get('/Process/InsInstitucional/institucional/29/pedidoenviado')
            .then(response => {
                this.contato = response.data;
            })
            .catch(error => {
                console.error(error);
            });

            let dadosArmazenados = localStorage.getItem(this.$DadosCompra);
                if (dadosArmazenados) {
                    dadosArmazenados = JSON.parse(dadosArmazenados);
                    if (dadosArmazenados.produtos[0] != null) {
                        dadosArmazenados.produtos = [];
                        dadosArmazenados.agenda[0].Obs = '';
                        localStorage.setItem(this.$DadosCompra, JSON.stringify(dadosArmazenados))
                        this.$store.commit('updateVariavelHeader2', dadosArmazenados.produtos.length);
                    }
                }

    },
}
</script>

<style>
.hhss {
    display: none;
}

.sli p {
    color: #ede3e3 !important;
}</style>
