
import { createRouter, createWebHistory } from 'vue-router'
import Home from '../views/HomeView.vue'
import Sobre from '../views/SobreView.vue'
import Contato from '../views/ContatoView.vue'
import Blog from '../views/BlogView.vue'
import BlogDetalhe from '../views/BlogDetalheView.vue'
import Informacoes from '../views/InformacoesView.vue'
import Produtosporcategoria from '../views/ProdutosporcategoriaView.vue'
import ProdutoDetalhe from '../views/ProdutoDetalheView.vue'
import Carrinho from '../views/CarrinhoView.vue'
import PedidoRealizado from '../views/PedidoRealizadoView.vue'

const routes = [
 { path: '/', name: 'Home', component: Home },
 { path: '/Sobre', name: 'Sobre', component: Sobre },
 { path: '/Contato', name: 'Contato', component: Contato },
 { path: '/Informacoes/:idInformacoes?/:nomeInformacoes?', name: 'Informacoes', component: Informacoes },
 { path: '/Produtosporcategoria/:idSegmentoProduto?/:nomeSegmento?', name: 'Produtosporcategoria', component: Produtosporcategoria},
 { path: '/ProdutosporcategoriaPesquisa/:chavepesquisa?', name: 'ProdutosporcategoriaPesquisa', component: Produtosporcategoria},
 { path: '/ProdutoDetalhe/:idProduto/:nomeProduto', name: 'ProdutoDetalhe', component: ProdutoDetalhe},
 { path: '/Blog/', name: 'Blog', component: Blog },
 { path: '/BlogDetalhe/:idBlog?/:nome?', name: 'BlogDetalhe', component: BlogDetalhe },
 { path: '/Carrinho', name: 'Carrinho', component: Carrinho },
{ path: '/PedidoRealizado', name: 'PedidoRealizado', component: PedidoRealizado },

]

const router = createRouter({
  history: createWebHistory(),
  routes,
})

router.beforeEach((to, from, next) => {
  window.scrollTo({ top: 0, behavior: 'smooth' })
  next()
})

export default router
