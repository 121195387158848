<template>
    <div>
        <div class="hidden-sm hidden-xs">
            <!-- HEADER -->
            <header>
                <!-- TOP HEADER -->
                <div id="top-header" v-if="topo!=''">
                    <div class="container">
                        <ul class="header-links pull-left">
                            <li><a :href="topo[0].itiLinkBtn1"><i class="fa fa-phone"></i> {{ topo[0].itiNome }} </a></li>
                            <li><a :href="topo[0].itiLinkBtn1"><i class="fa fa-whatsapp"></i> {{ topo[0].itiReferencia }}</a></li>
                        </ul>
                        <ul class="header-links pull-right hidden-sm hidden-xs">
                            <li>
                                <a v-if="redes[0].itiNome != '' && redes[0].itiNome != null" :href="redes[0].itiNome"
                                    target="_blank"><i class="fa fa-facebook"></i></a>
                            </li>
                            <li>
                                <a v-if="redes[0].itiCampoExtra2 != '' && redes[0].itiCampoExtra2 != null"
                                    :href="redes[0].itiCampoExtra2" target="_blank"><i class="fa fa-linkedin"></i></a>
                            </li>
                            <li>
                                <a v-if="redes[0].itiReferencia != '' && redes[0].itiReferencia != null"
                                    :href="redes[0].itiReferencia" target="_blank"><i class="fa fa-instagram"></i></a>
                            </li>
                            <li>
                                <a v-if="redes[0].itiCampoExtra1 != '' && redes[0].itiCampoExtra1 != null"
                                    :href="redes[0].itiCampoExtra1" target="_blank"><i class="fa fa-whatsapp"></i></a>
                            </li>
                        </ul>
                    </div>
                </div>
                <!-- /TOP HEADER -->
                <!-- MAIN HEADER -->
                <div id="header">
                    <!-- container -->
                    <div class="container">
                        <!-- row -->
                        <div class="row">
                            <!-- LOGO -->
                            <div class="col-md-3 col-xs-6">
                                <div class="header-logo">
                                    <router-link to="/" class="logo">
                                        <img src="/img/logo.png" style="width:170px;height:auto;" alt="">
                                    </router-link>
                                </div>
                            </div>
                            <!-- /LOGO -->
                            <!-- SEARCH BAR -->
                            <div class="col-md-6 hidden-sm hidden-xs">
                                <div class="header-search">
                                    <form>
                                        <input class="input-select" style="width: 400px;" v-model="pesquisaChave"
                                            @keyup.enter="Pesquisa()" placeholder="Qual produto você procura">
                                        <button class="search-btn" @click="Pesquisa()">Pequisar</button>
                                    </form>
                                </div>
                            </div>
                            <!-- /SEARCH BAR -->
                            <!-- ACCOUNT -->
                            <div class="col-md-3 hidden-xs hidden-sm clearfix">
                                <div class="header-ctn">
                                    <!-- Wishlist -->
                                    <div>
                                    </div>
                                    <!-- /Wishlist -->
                                    <!-- Cart -->
                                    <div class="dropdown">
                                        <router-link to="/carrinho" title="Carrinho">
                                            <i class="fa fa-shopping-cart"></i>
                                            <span>Orçamento</span>
                                            <div v-if="variavelHeader2 != ''" class="qty">{{ variavelHeader2 }}</div>
                                        </router-link>
                                    </div>
                                    <!-- /Cart -->
                                </div>
                            </div>
                            <!-- /ACCOUNT -->
                        </div>
                        <!-- row -->
                    </div>
                    <!-- container -->
                </div>
                <!-- /MAIN HEADER -->
            </header>
            <!-- /HEADER -->
            <!-- NAVIGATION -->
            <nav id="navigation">
                <!-- container -->
                <div class="container">
                    <!-- responsive-nav -->
                    <div id="responsive-nav">
                        <!-- NAV -->
                        <ul class="main-nav nav navbar-nav">
                            <li><router-link to="/">Home</router-link></li>
                            <li><router-link to="/sobre">Sobre</router-link></li>
                            <li><router-link to="/Produtosporcategoria">Produtos por categorias</router-link></li>
                            <li><router-link to="/Blog">Blog</router-link></li>
                            <li><router-link to="/contato">Contato</router-link></li>
                        </ul>
                        <!-- /NAV -->
                    </div>
                    <!-- /responsive-nav -->
                </div>
                <!-- /container -->
            </nav>
            <!-- /NAVIGATION -->
        </div>

        <div class="container hidden-lg hidden-md" style="padding-bottom: 60px;">
            <v-navigation-drawer v-model="drawer">
                <!-- Conteúdo do menu lateral -->
                <router-link to="/" class="scrollto"
                    style="color: #000;letter-spacing: 2px;padding-right: 0.3em;font-size: 25px;padding-top: 15px;">
                    <img src="/img/logo.png" style="width:215px;margin: 15px;">
                </router-link>
                <v-list>
                    <v-list-item link to="/">
                        <v-list-item-title>Início</v-list-item-title>
                    </v-list-item>
                    <v-list-item link to="/sobre">
                        <v-list-item-title>sobre</v-list-item-title>
                    </v-list-item>
                    <v-list-item link @click="drawer3 = !drawer3; drawer = !drawer;">
                        <v-list-item-title class="d-inline">Produtos por Categorias</v-list-item-title>
                        <v-list-item-icon>
                            <v-icon>mdi-chevron-right</v-icon>
                        </v-list-item-icon>
                    </v-list-item>
                    <v-list-item link to="/blog">
                        <v-list-item-title>Blog</v-list-item-title>
                    </v-list-item>
                    <v-list-item link to="/contato">
                        <v-list-item-title>Contato</v-list-item-title>
                    </v-list-item>
                    <v-list-item link @click="drawer2 = !drawer2; drawer = !drawer;">
                        <v-list-item-title class="d-inline">Informações Gerais</v-list-item-title>
                        <v-list-item-icon>
                            <v-icon>mdi-chevron-right</v-icon>
                        </v-list-item-icon>
                    </v-list-item>
                    <v-list-item link to="/Carrinho">
                        <v-list-item-title>Carrinho</v-list-item-title>
                    </v-list-item>
                </v-list>
                <v-row class="justify-content-center" style="padding-top:30px;">
                    <div class="d-flex justify-content-center" v-if="redes != ''">
                        <a v-if="redes[0].itiNome != '' && redes[0].itiNome != null" :href="redes[0].itiNome"
                            target="_blank" title="Facebook" style="color:#000000;font-size:13px;padding-right:15px;"><i
                                class="icon-facebook-f"></i></a>
                        <a v-if="redes[0].itiReferencia != '' && redes[0].itiReferencia != null"
                            :href="redes[0].itiReferencia" target="_blank" title="Instagram"
                            style="color:#000000;font-size:13px;padding-right:15px;"><i class="icon-instagram"></i></a>
                        <a v-if="redes[0].itiCampoExtra1 != '' && redes[0].itiCampoExtra1 != null"
                            :href="redes[0].itiCampoExtra1" target="_blank" title="Whatsapp"
                            style="color:#000000;font-size:13px;padding-right:15px;"><i class="icon-whatsapp"></i></a>
                        <a v-if="redes[0].itiCampoExtra2 != '' && redes[0].itiCampoExtra2 != null"
                            :href="redes[0].itiCampoExtra2" target="_blank" title="Linkedin" style="color:#000000;"><i
                                class="icon-linkedin"></i></a>
                    </div><!-- End .social-icons -->

                </v-row>
            </v-navigation-drawer>


            <v-navigation-drawer v-model="drawer2">
                <!-- Conteúdo do menu lateral -->
                <router-link to="/" class="scrollto"
                    style="color: #000;letter-spacing: 2px;padding-right: 0.3em;font-size: 25px;padding-top: 15px;">
                    <img src="/img/logo.png" style="width:215px;margin: 15px;">
                </router-link>
                <v-list>
                    <v-list-item link @click="drawer2 = !drawer2; drawer = !drawer;">
                        <v-list-item-icon>
                            <v-icon class="d-inline">mdi-chevron-left</v-icon>
                        </v-list-item-icon>
                        <v-list-item-title class="d-inline">Voltar</v-list-item-title>
                    </v-list-item>
                    <div v-for="itens in informacoes" :key="itens.itiId">
                        <v-list-item link
                            :to="'/informacoes/' + itens.itiId + '/' + itens.itiNome.replaceAll(' ', '-').replaceAll('/', '').replaceAll(',', '')">
                            <v-list-item-title> {{ itens.itiNome }}</v-list-item-title>
                        </v-list-item>
                    </div>
                </v-list>
            </v-navigation-drawer>

            <v-navigation-drawer v-model="drawer3">
                <!-- Conteúdo do menu lateral -->
                <router-link to="/" class="scrollto"
                    style="color: #000;letter-spacing: 2px;padding-right: 0.3em;font-size: 25px;padding-top: 15px;">
                    <img src="/img/logo.png" style="width:215px;margin: 15px;">
                </router-link>
                <v-list>
                    <v-list-item link @click="drawer3 = !drawer3; drawer = !drawer;">
                        <v-list-item-icon>
                            <v-icon class="d-inline">mdi-chevron-left</v-icon>
                        </v-list-item-icon>
                        <v-list-item-title class="d-inline">Voltar</v-list-item-title>
                    </v-list-item>
                    <div v-for="seg in SegmentosMenu" :key="seg.psgId" class="d-inline">
                        <v-list-item link
                            :to="'/Produtosporcategoria/' + seg.psgId + '/' + seg.psgNomeSegmento.replaceAll(' ', '-').replaceAll('/', '').replaceAll(',', '')">
                            <i class="fa fa-arrow-circle-right" style="color:#e8522c;"></i>
                            <v-list-item-title
                                style="font-weight: 500; font-size: 14px;padding-left: 8px;color:#333333;display: inline-flex;">
                                {{ seg.psgNomeSegmento }}</v-list-item-title>
                        </v-list-item>
                    </div>
                </v-list>
            </v-navigation-drawer>

            <!-- Conteúdo principal -->
            <v-app-bar app>
                <v-app-bar-nav-icon @click.stop="drawer = !drawer"></v-app-bar-nav-icon>
                <v-row align="center" class="logo-cart-container">
                    <v-col class="logo-col">
                        <img src="/img/logo.png" style="height: 50px;">
                    </v-col>
                    <v-col class="icon-col">
                        <div class="dropdown cart-dropdown">
                            <router-link to="/carrinho" title="Carrinho">
                                <div class="icon">
                                    <i class="icon-shopping-cart"></i>
                                    <span v-if="variavelHeader2 != ''" class="cart-count">{{ variavelHeader2 }}</span>
                                </div>
                            </router-link>
                        </div><!-- End .cart-dropdown -->
                    </v-col>
                    <v-col class="icon-col" style="padding-right: 20px;">
                        <div class="dropdown cart-dropdown">
                            <div class="icon" style="padding-top: 6px;padding-right: 10px;"
                                @click="showSearch = !showSearch">
                                <i class="icon-search" style="font-size: 25px;"></i>
                            </div>
                        </div>
                    </v-col>
                </v-row>
            </v-app-bar>
            <v-row v-if="showSearch" class="search-container">
                <v-col>
                    <div class="header-search-wrapper">
                        <input type="search" class="form-control" v-model="pesquisaChave" @keyup.enter="Pesquisa()"
                            placeholder="Pesquise..." required>
                        <a href="#" class="" @click="Pesquisa()" style="margin-bottom: 20px"><i class="icon-search"
                                style="font-size: 30px;"></i></a>
                    </div>
                </v-col>
            </v-row>

        </div>

    </div>
</template>

<script>
import axios from 'axios';
import Cookies from 'js-cookie';
import { mapState } from 'vuex';

export default {
    name: 'PageHeader',
    components: {

    },
    data: () => ({
        topo: [],
        redes: [],
        pesquisaChave: '',
        informacoes: [],
        drawer: false,
        drawer2: false,
        drawer3: false,
        showSearch: false,
        Segmentos: [],
        SegmentosMenu: [],
    }),
    methods: {
        calculatotal() {
            let dadosArmazenados = localStorage.getItem(this.$DadosCompra);
            if (dadosArmazenados) {
                dadosArmazenados = JSON.parse(dadosArmazenados);
                this.$store.commit('updateVariavelHeader2', dadosArmazenados.produtos.length);
            }
           
        },
        Pesquisa() {
            if (this.pesquisaChave == "") {
                this.$mensagemAviso("Digite uma palavra chave para pesquisa.")
            }
            else {
                const novaURL = `/ProdutosporcategoriaPesquisa/${this.pesquisaChave}`;
                window.history.pushState({}, '', novaURL);
            }
        }
    },
    async created() {

        const cookieData = Cookies.get(this.$CookieCliente);
        if (cookieData) {
            const _dadosRecuperados = JSON.parse(cookieData);
            this.$store.commit('updateVariavelHeader1', "Olá, " + _dadosRecuperados.nomeUsuario);
        }
        this.calculatotal();

        axios.get('/Process/InsInstitucional/institucional/9/cabecalho')
            .then(response => {
                this.topo = response.data;
            })
            .catch(error => {
                console.error(error);
            });

        axios.get('/Process/InsInstitucional/institucional/20/redessociais')
            .then(response => {
                this.redes = response.data;
            })
            .catch(error => {
                console.error(error);
            });

        axios.get('/Process/InsInstitucional/institucional/18/informacoes')
            .then(response => {
                this.informacoes = response.data.filter(x => x.itiVisivel).sort((a, b) => a.itiOrdem - b.itiOrdem);
            })
            .catch(error => {
                console.error(error);
            });

        await axios.get('/Process/ProSegmentos/GetAll')
            .then(response => {
                this.Segmentos = response.data;
                this.SegmentosMenu = this.Segmentos.filter(x => x.psgIdPai == 0 && x.psgVisivel).sort((a, b) => {
                    const nomeA = a.psgNomeSegmento || "";
                    const nomeB = b.psgNomeSegmento || "";
                    return nomeA.localeCompare(nomeB);
                });
            })
            .catch(error => {
                console.error(error);
            });
    },
    computed: {
        ...mapState(['variavelHeader1', 'variavelHeader2'])
    },
}
</script>

<style scoped>
@media (max-width:768px) {
    .logo-col {
        flex: 4;
        /* Ajuste o valor para controlar o tamanho do logo */
    }

    .icon-col {
        flex: 1;
        /* Ajuste o valor para controlar o tamanho dos ícones e o espaço entre eles */
    }

    .logo-img {
        width: 100%;
        /* Para fazer o logo ocupar 100% da largura da coluna */
    }

    .search-container {
        position: absolute;
        background-color: white;
        /* Cor de fundo do campo de pesquisa */
        z-index: 1;
        /* Para sobrepor o conteúdo abaixo */
        width: 100%;
        /* Para ocupar a largura da barra de aplicativos */
        top: 100px;
        /* Ajuste conforme necessário para a posição vertical desejada */
        padding: 10px;
        /* Espaçamento interno do campo de pesquisa */
        box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
        /* Sombra, opcional */
    }

    .search-input {
        width: 100%;
        padding: 10px;
    }

    .header-search-wrapper {
        display: flex;
        align-items: center;
        justify-content: space-between;
    }
}</style>
