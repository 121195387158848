<template>
  <div>
    <!-- BREADCRUMB -->
    <div id="breadcrumb" class="section">
        <!-- container -->
        <div class="container">
            <!-- row -->
            <div class="row">
                <div class="col-md-12">
                    <ul class="breadcrumb-tree">
                      <li><router-link to="/">Home</router-link></li>
                        <li class="active">Sobre a FR Distribuidora</li>
                    </ul>
                </div>
            </div>
            <!-- /row -->
        </div>
        <!-- /container -->
    </div>
    <!-- /BREADCRUMB -->
    <!-- SECTION -->
    <div class="section" v-if="sobreCestas!=''">
        <!-- container -->
        <div class="container">
            <!-- row -->
            <div class="row">
                <div class="col-lg-6">
                    <h3>{{sobreCestas[0].itiNome}}</h3>
                    <br />
                    <p v-html="sobreCestas[0].itiResumo1" style="text-align: justify;"></p>
                </div>
                <div class="col-lg-6">
                    <img v-if="sobreCestas[0].insInstitucionalImagens!=''" :src="$imgURL + sobreCestas[0].insInstitucionalImagens[0].itgUrlImagem" style="width: 100%;border-radius: 5px;" />
                </div>
            </div>
            <div class="row" style="padding-top: 60px;" v-if="sobreCestas[0].itiReferencia!=''">
                <div class="col-lg-12" style="text-align: center; padding-bottom: 20px;">
                    <h3>Vídeo Institucional</h3>
                </div>
                <div class="col-lg-12" style="text-align: center; padding-bottom: 20px;">
                    <iframe width="100%" height="350" :src="sobreCestas[0].itiReferencia"
                        title="YouTube video player" frameborder="0"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                        allowfullscreen></iframe>
                </div>
            </div>
            <!-- /row -->
        </div>
        <!-- /container -->
    </div>
  </div>
</template>

<script>
//import moment from 'moment'
import axios from 'axios';
import VueJsCounter from 'vue-js-counter'
import { Swiper, SwiperSlide } from 'swiper/vue';
import { useHead } from '@vueuse/head';

export default {
  name: 'SobreView',
  components: {
    VueJsCounter,
    Swiper,
    SwiperSlide
  },
  data: () => ({
    sobretopo: [],
    sobreCestas:[],
    numeros:[],
    numerosfundo:[],
    depoimentos:[],  //owl-carousel owl-simple owl-testimonials-photo
  }),
  methods: {
    carregaMetas() {
			useHead({
				title: 'Sobre - FR Distribuidora'
			})
		},
  },
  created() {
    this.carregaMetas();

    axios.get('/Process/InsInstitucional/institucional/12/sobre')
      .then(response => {
        this.sobreCestas = response.data;
      })
      .catch(error => {
        console.error(error);
      });

  },
}
</script>

<style>
.das {
  display: block !important;
}

.slider-text h2 p {
  font-size: 60px;
  color: #081624;
  padding: 10px;
  display: inline-block;
  background: #99CC33;
  line-height: 1;
}</style>
